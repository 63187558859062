import * as React from "react"
import { graphql } from 'gatsby';

import Layout from "../components/layout"
import Seo from "../components/seo"

// The bridge for Gatsby Background Image in V3
import { BgImage } from 'gbimage-bridge'

// All the gatsby-plugin-image goodness
import { getImage } from "gatsby-plugin-image"

import { Container, Row, Col, Form, Button } from "react-bootstrap"

const SecondPage = ({data}) => (
  <Layout>
    <BgImage 
      image={getImage(data.indexImage)} 
      className="masthead"
    >
       <div className="black-overlay">
            <div className="content-box">
              <h1>Schedule a Tour</h1>
            </div>
          </div>
    </BgImage>

    <Container>
                <Row>
                    <Col lg={6}>
                    <p>
                        For all leasing inquiries, please call 912-571-8170, email <a href="mailto:Info@WorkTheWick.com">Info@WorkTheWick.com</a>, or enter your info and we’ll reach out to you!
                    </p>
                    </Col>
                    <Col lg={6} className="mb-3">
                    <Form 
                        name="contact v9"
                        method="post"
                        action="/success/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit="submit"
                    >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact v9" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" />
                    </label>
                    </p>
                     


                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control 
                                        type="text" name="first-name"
                                     />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                            <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control 
                                       type="text" name="last-name"

                                     />
                            </Form.Group>
                            </Col>
                        </Row>


                            
                        <Form.Group>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                                required
                                type="email" 
                                name="email" 
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control 
                                required
                                type="text" 
                                name="phone" 
                            />
                        </Form.Group>
                        
                        <Form.Group>
                            <Form.Label>How much space are you looking for?</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                name="how-much-space"
                                rows="3"
                                placeholder="Examples: About 150-200 square feet; Enough for 3 people working full-time; A small office for 1 but lots of storage space..."
                             />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Please tell us a little bit about your business.</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                name="more-about-business"
                                rows="3"
                                placeholder="What do you do? (but really, what don’t you do…)"
                             />
                        </Form.Group>
                        <Button type="submit">Submit</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>




  </Layout>
)

export const Head = () => <Seo title="Schedule a Tour" />

export default SecondPage

export const query = graphql`
  query {
    indexImage: file(relativePath: {eq: "wick-1-outside-cars.jpg"}) {
      id
      base
      relativePath
      childImageSharp {
        gatsbyImageData(
          width: 2000
          webpOptions: {quality: 30}
          placeholder: BLURRED
          blurredOptions: {width: 30}
        )
      }
    }
  }
`